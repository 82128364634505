
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
}

